import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Restaurant } from '../components/Restaurant'
import soupOne from '../assets/originals/SOUP_one.jpg'
import soupTwo from '../assets/originals/fabricaSoup2.jpg'
import soup from '../assets/originals/fabricaSoup1.jpg'
import soupThree from '../assets/originals/fabricaSoup3.jpg'
import menu from '../assets/originals/Menu.jpg'
import menuInPdf from '../assets/originals/Menu.pdf'

const IndexPage = ({ data }) => {
  return (
    <Layout showHeader>
      {/*<SEO title="Станция" />*/}
      <Restaurant
        page="fabrika"
        fluid={data.mainFabrica.childImageSharp.fluid}
        gallery={[soupOne, soupTwo, soup, soupThree]}
        menu={menu}
        menuInPdf={menuInPdf}
        delivery={
          <>
            Доставка:{' '}
            <a href="https://eda.bond.od.ua/#supstanciya-" target="_blank" rel="noreferrer noopener">
              Бонд.Еда
            </a>
          </>
        }
      />
    </Layout>
  )
}

export const fabricaQuery = graphql`
  query {
    mainFabrica: file(relativePath: { eq: "SOUP_title.JPG" }) {
      ...fluidImage
    }
  }
`

export default IndexPage
