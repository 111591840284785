export default {
  bar: {
    title: 'Бар Станция',
    description: `В баре мы наливаем локальное и не только вино, а также крафтовое пиво, готовим авторские испанские коктейли и лёгкие закуски - тапасы (традиционная испанская закуска, "one bite food"). Станция идеально подходит для ненавязчивого вечера с друзьями, возлюбленными, коллегами, и даже самим собой. На выходных, правда, шумим сильно и ярко, но всё так же легко. Лёгкий бар для лёгких вечеров.`,
    contacts: {
      time: 'Каждый день с 19:00 до 24:00',
      tel: '+380 66 51 31 461',
      address: 'Одесса, улица Канатная, 6',
      fb: 'tapasbar.station',
      ig: 'station.tapasbar',
      map: [46.483343, 30.74877],
    },
  },
  fabrika: {
    title: 'Супстанція',
    description:
      'Ми знаходимося в центрі міста в найсучаснішій творчій локації в Одесі – Фабрика, яка поєднує в собі студії, майстерні, шоу-руми, офіси, техно-клуб – іншими словами: все що хочеш. Не відстаючи від швидкого ритму міста, у нашому бістро на Фабриці ми постійно створюємо нові яскраві страви, нескінченно експериментуємо та ризикуємо, дивуємо незвичайними та локальними продуктами, і, головне, смачно годуємо супом. Суп - Їжа, і це не просто слова для нас ;)',
    contacts: {
      time: 'Кожен день з 11:00 до 19:00',
      tel: '+380 66 51 31 461',
      address: 'Одеса, вул. Канатна, 6 (на розі Карантинного узвізу)',
      // bot: 'SupstanciyaBot',
      fb: 'soup.station.od',
      ig: 'soup.station',
      map: [46.483343, 30.74877],
    },
  },
  'green-theater': {
    title: 'Супстанция в Зелёном театре',
    description: `В Зелёном театре началась наша история, и каждый год это место становится нашей летней резиденцией для суповарения. 
Супстанция в Зелёном театре - это современный городской стрит-фуд, который находится в крутом пространстве и объединяет в себе одновременно урбанистический современный стиль быстрого питания и любимые домашние блюда, известные одесситам с детства. Поход в Зелёный театр - это как отправиться в деревню к бабушке. К бабушке, которая влюблена в современное искусство и гастрономию.
`,
    contacts: {
      date: 'C мая по октябрь: ',
      time: 'Пн-Bс 10:00-22:00',
      tel: '+380 66 51 31 461',
      address: 'Одесса, улица Маразлиевская, 1/6',
      fb: 'soup.station.od',
      ig: 'soup.station.greentheatre',
      map: [46.478632, 30.751102],
    },
  },
}

export const NEW_YEAR_CONFIG = {
  poem:
    'Хочешь время сэкономить?\n' +
    'Не устать? У плиты не стоять?\n' +
    'И в праздник самому не готовить?\n' +
    'Есть решение простое:\n' +
    'Не бежать стремглав в магаз.\n' +
    'А в Супстанции сделать заказ:\n' +
    'Хоть холодца большое блюдо.\n' +
    'Хоть оливье огромный таз!\n',
  menu: [
    [
      { name: 'Знаменитый холодец', weight: '0,5кг', price: '170 грн' },
      { name: 'Холодец', weight: '1,2кг', price: '380 грн' },
    ],
    [
      { name: 'Оливье с индейкой', weight: '1кг', price: '350 грн' },
      { name: 'Оливье с лососем', weight: '1кг', price: '500 грн' },
      { name: 'Веган оливье с авокадо', weight: '1кг', price: '440 грн' },
    ],
    [
      { name: 'Хумус израильский', weight: '0,4кг', price: '130 грн' },
      { name: 'Хумус с печёными овощами', weight: '0,6кг', price: '170 грн' },
      { name: 'Хумус с песто', weight: '0,6кг', price: '170 грн' },
    ],
    [{ name: 'Запечённые куриные ножки в мандариновом соусе с картофелем', weight: '1кг', price: '175 грн' }],
  ],
  notes: [
    { regular: 'все блюда подаются в одноразовой посуде' },
    { regular: 'к каждой порции хумуса мы испечём по 3 питы' },
    { regular: 'предзаказ принимаем', bold: 'до 30.12' },
    { regular: 'забрать свой заказ можно самовывозом в любой нужный день или', bold: '31.12 до 14:00' },
  ],
}
